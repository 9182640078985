import React, { useEffect } from "react";
import THTInfo from "./THTInfo";

import { useAuth } from '../auth/AuthContext';


function calculateFutureDate(dayAndMonth) {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 2);

    const [day, month] = dayAndMonth.split('-').map(Number);
    let year = today.getFullYear();

    // Создаем дату с текущим годом и указанными месяцем и днем
    let futureDate = new Date(year, month - 1, day);

    // Определяем условие для переноса даты на следующий год
    // Дата должна быть строго меньше вчерашнего дня
    if (futureDate < yesterday) {
        futureDate.setFullYear(year + 1);
        const daysDiff = Math.floor((futureDate - today) / (1000 * 60 * 60 * 24));

        if (daysDiff > 88) {
            const accept = window.confirm(`${day}-${month}-${year+1} was ingevuld. Is dit correct? (${daysDiff} dagen)`);
            if (!accept) {
                return null;
            }
        }
    }

    // Форматируем дату для вывода в формате ДД-ММ-ГГГГ
    let formattedDate = `${('0' + futureDate.getDate()).slice(-2)}-${('0' + (futureDate.getMonth() + 1)).slice(-2)}-${futureDate.getFullYear()}`;
    return formattedDate;
}

function calculateFutureDateFromDay(day) {
  const today = new Date();
  if (today.getDate() > parseInt(day)) {
    today.setDate(1);
    today.setMonth(today.getMonth() + 1);
  }
  today.setDate(parseInt(day));
  today.setHours(0, 0, 0, 0);
  let formattedDate = `${("0" + today.getDate()).slice(-2)}-${("0" + (today.getMonth() + 1)).slice(-2)}-${today.getFullYear()}`;
  return formattedDate;
}

function Item({item, openBeheer, quickAction, newDate, voorraad}) {
    const [currentNewDateTmp, setCurrentNewdateTmp] = React.useState(item.new_date === null?"0":item.new_date);
    const initNewDate = item.edited?(item.new_date === null?"0":item.new_date):"";
    const [date, setDate] = React.useState(null);
    
    const [editing, setEditing] = React.useState(false);
    const [newDateDisplay, setNewDateDisplay] = React.useState(item.new_date === null?"0":item.new_date);
    const [moetGestickerdWorden, setMoetGestickerdWorden] = React.useState(false);

    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

    const auth = useAuth();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const applyNewDate = (e) => {
        const parst = currentNewDateTmp.split('-');
        const len = parst.length;
        if (len == 3) {
            newDate(currentNewDateTmp);
        } else if (len == 2) {
            const date = calculateFutureDate(currentNewDateTmp);
            console.log("DATE", date);
            if (date) newDate(date);
            else setCurrentNewdateTmp(item.new_date === null?"0":item.new_date);
        } else if (len == 1) {
            if (currentNewDateTmp == "") {
                newDate("");
            } else if (currentNewDateTmp == "0") {
                newDate("0");
            } else {
                if (parseInt(currentNewDateTmp) > 31) {
                    alert('Invalid date');
                    setCurrentNewdateTmp(item.new_date === null?"0":item.new_date);
                } else {
                    const date = calculateFutureDateFromDay(currentNewDateTmp);
                    newDate(date);
                }
            }
        }
    }


    useEffect(() => {
        if (!editing) {
            if (item.edited) {
                setCurrentNewdateTmp(item.new_date === null?"0":item.new_date);
            } else {
                setCurrentNewdateTmp("");
            }
        }
    }, [item]);





    useEffect(() => {
        if (item.date) { // 16-05-2021
            const tmp = item.date.split('-');
            const date = new Date(tmp[2], tmp[1] - 1, tmp[0]);
            date.setHours(0, 0, 0, 0);
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            if (date < today) {
                setDate("eruit");
            } else {
                if (tmp[2] == today.getFullYear()) {
                    setDate(tmp[0] + '-' + tmp[1]);
                } else {
                    setDate(item.date);
                }
            }
        } else {
            setDate("manco");
        }
    }, [item.date]);





    const updateNewDate = (date) => {

        if (date.length == 3) {
            if (date[2] != '-') {
                date = date.substring(0, 2) + '-' + date.substring(2);
            } else {
                date = date.substring(0, 2);
            }
        } 

        if (date.length == 6) {
            if (date[5] != '-') {
                date = date.substring(0, 5) + '-' + date.substring(5);
            } else {
                date = date.substring(0, 5);
            }
        }

        setCurrentNewdateTmp(date);
    }

    useEffect(() => {
        const tmp = currentNewDateTmp.split('-');
        if (tmp.length == 3) {
            const today = new Date();
            if (tmp[2] == today.getFullYear()) {
                setNewDateDisplay(tmp[0] + '-' + tmp[1]);
            } else {
                setNewDateDisplay(currentNewDateTmp);
            }

            //setMoetGestickerdWorden
            //IF date is today or tomorrow
            const date = new Date(tmp[2], tmp[1] - 1, tmp[0]);
            date.setHours(0, 0, 0, 0);
            const todayDate = new Date();
            todayDate.setHours(0, 0, 0, 0);
            const tomorrow = new Date();
            tomorrow.setDate(todayDate.getDate() + 1);
            tomorrow.setHours(0, 0, 0, 0);  

            if (date.getTime() <= tomorrow.getTime()) {
                setMoetGestickerdWorden(true);
            } else {
                setMoetGestickerdWorden(false);
            }


        } else {
            setNewDateDisplay(currentNewDateTmp);
            setMoetGestickerdWorden(false);
        }



    }, [currentNewDateTmp]);


    // <button className="data" onClick={() => quickAction()}>
    //             <div className="item-name">
    //                 {item.name}
    //             </div>
    //             <div className="item-order-number">
    //                 {item.order_number}
    //             </div>
    //         </button>


    return (
        <li className={(item.edited ? "edited ": "")+ "item-block"}>
            <button onClick={() => openBeheer()}>
                <div className="item-img">
                <img src={item.img_path?"/api/static/img/" + item.img_path:'/api/static/landscape-placeholder.svg'} width={150} height={150}/>
                </div>
            </button>
            <div className="data">
                <div className="item-name">
                    {item.name}
                </div>
                <div className="item-order-number">
                    <span>
                        {item.order_number}
                    </span>
                    <span>
                        {isNaN(voorraad)?"":voorraad}
                    </span>
                </div>
            </div>
            {windowWidth > 700 && <input type="tel" value={date} readOnly style={
                {
                    borderColor: "transparent",
                }
            }/>}
            <label htmlFor={item.id}>
                <input type="tel" id={item.id} className={moetGestickerdWorden?"dark-red":""} value={newDateDisplay} onChange={(e) => updateNewDate(e.target.value)} onBlur={() => {
                    setEditing(false);
                    if (currentNewDateTmp != initNewDate) {
                        applyNewDate();
                    }
                }} onFocus={(e) => {setEditing(true);
                    //Select all text
                    const input = e.target;
                    input.select();
                }} placeholder={
                    date
                }/>
            </label>

            {
                windowWidth > 910 && auth.isAdmin() && <THTInfo item={item}/>
            }
        </li>
    );
}


function DailyListTable({meters, openBeheer, quickAction, newDate, voorraad}) {
    return (
        <ul className="main-list">
            {
                meters.map((meter, index) => (
                    meter.length > 0 && 
                    <li key={index}>
                            <div className="heading" id={"meter-"+index} onClick={
                                () => {
                                    let id = prompt("Vul het nummer van de meter in om naar toe te scrollen");
                                    if (id) {
                                        id--;
                                        let element = document.getElementById("meter-"+id);

                                        while (!element && id > 0) {
                                            id--;
                                            element = document.getElementById("meter-"+id);
                                        }
                                        if (element) {
                                            element.scrollIntoView();
                                        }
                                    }
                                }
                            }>
                                <h2>Meter {index+1}</h2>
                            </div>
                            <ul>
                                {
                                    meter.map((item, index) => (
                                        <Item key={index+100} item={item} openBeheer={() => {
                                            openBeheer(item.id);
                                        }} quickAction={quickAction} newDate={(date) => {
                                            newDate(item.id, date);
                                        }} voorraad={voorraad?(item.order_number in voorraad ? voorraad[item.order_number] : NaN):NaN}
                                        />
                                    ))
                                }
                            </ul>
                    </li>
                ))
            }
        </ul>
    );
}


export default DailyListTable;